@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
/* mobile navbar and home section */
.nav-btn{
    font-size: 2rem;
    background: none;
    border: none;
    color: white;
   }


.header{
background: url('/public/assets/bg-mobile.png');
min-height: 500px;
width: auto;
background-repeat: no-repeat ;
background-size: cover;
display: flex;
justify-content: space-between;
padding: 20px;
}

.header h1{
    color: white;
    font-weight: 200;
    font-size: 1.3rem;
    
}

.header h1 SPAN{
    font-weight: 700;
}

.services-home{
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 30px;
}

.services-home a{
    color: white;
    background: linear-gradient(90deg,rgb(0, 153, 255),rgb(0, 238, 255));
    text-decoration: none;
    padding: 10px 30px;
    font-weight: 500;
    font-size: 1.5rem;
    border-radius: 10px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.349);
}

.home-heading{
text-align: center;
font-size: 2rem;
font-weight: 400;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.374);
}

.description{
    margin: 30px;
}

.description em{
    font-weight: 600;
}



/*desktop navbar and home section */

.desktop-navbar{
    background: url('/public/assets/bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 800px;
    width: auto;
    padding-inline:100px ;
    padding-top: 20px;
}

.nav-group{
    display: flex;
    justify-content: space-between;
}

.nav-group a{
    text-decoration: none;
    color: white;
}
.links{
    margin-top:30px ;
    display: flex;
    gap: 60px;
    height: fit-content;
}

.tel{
    background: linear-gradient(90deg,rgb(0, 221, 255),rgb(0, 110, 255));
    height: fit-content;
    width: fit-content;
    padding: 10px 20px;
    position: relative;
    bottom: 10px;
    border-radius: 10px;
    font-weight: 600;
    text-shadow: 2px 2px 1px black;

}

.nav-group img{
    height: 60px;
    margin-top: 20px;
   position: relative;
   bottom: 20px;
}

.hero-title{
   text-align: center;
    color: white;
    font-size: 3.5rem;
    max-width: 700px;
    margin: 100px auto ;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.468);
}

.hero-paragraph{
    text-align: center;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.411);
    padding: 20px;
    max-width: 650px;
    color: rgba(255, 255, 255);
    
    line-height: 2.5rem;
    position: relative;
    bottom: 70px;
    margin: 0px auto;
    text-shadow: 3px 3px 3px black;
}

.hero-btn{
    position: absolute;
    left: 48%;
    
    text-decoration: none;
    color: white;
    background: linear-gradient(90deg,rgb(0, 221, 255),rgb(0, 110, 255));
    padding: 10px 50px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.461);
}

.home-heading-desktop{
    display: flex;
    justify-content: center;
    font-size: 2rem;
    margin-top:50px ;
}

.description-desktop{
    max-width: 820px;
    margin: 20px auto;
    line-height: 2rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.587);
}

.line{
    max-width: 150px;
    margin: 30px auto;
    background: linear-gradient(90deg,rgb(0, 221, 255),rgb(0, 110, 255));
    height: 3px;
}

.main-section{
    margin: 50px 0px;
}


.services{
    background-color:rgb(0, 140, 255) ;
    color: white;
}

.services h2{
    text-align: center;
    padding-top: 20px;
    font-weight: 800;
    font-style: italic;
}

.services p{
    margin:0px 30px;
    line-height: 2rem;
    text-align: center;
}

.service-line{
    background-color: white;
    height: 3px;
    width: 8%;
   margin-top: 10px;
   margin-inline: auto;
   margin-bottom:30px ;

}

.services-desktop{
    background-color:rgb(0, 140, 255) ;
    color: white;
    width: 100%;
    padding: 50px;
}
.services-desktop h2{
    text-align: center;
    font-size: 2.5rem;
    padding-top: 20px;
    font-style:italic;
}
.service-description{
    font-size: 1.2rem;
    max-width: 850px;
    margin: 20px auto;
    line-height: 2.5rem;
    font-weight: 400;
    text-align: center;
    padding-bottom: 20px;
}
.card-group{
   display: flex;
   max-width: 80%;
   flex-wrap: wrap;
   gap: 20px;
   margin:auto;
}
.card{
    background-color: white;
    color:rgb(0, 140, 255);
    max-width:fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    width: 400px;
}

.card img{
    width: 400px;
}

.card-name{
   
    font-weight: bold;
    font-size: 1.5rem;
    padding: 20px 0px;
    text-align: center;
}

.card-group-mobile{
    display:flex;
    flex-direction: column;
    margin: 30px 7vh;
    gap: 20px;
   padding: 30px 0px;
}
.card-mobile{
    color:rgb(0, 140, 255); ;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}
.card-mobile img{
    width: 300px;
    background-color: white;
   
}

.card-name-mobile{
    background-color: white;
    font-weight: bold;
    font-size: 1.5rem;
    padding: 15px 10px;
    text-align: center;
}



.drawer a{
    text-align: center;
    padding:0 5vh;
    text-decoration: none;
    color: rgb(0, 187, 255);
    margin: 30px 0px;
    padding: 20px 10px;
    position: relative;
    top: 100px;
    display: flex;
    justify-content:center;
    align-items: center;
    gap:20px;
    font-size: 1.2rem;
}
.drawer-icon{
    font-size: 1.5rem;
}

.drawer img{
width: 80px;
margin: 30px 70px;
}


.nav-mob-gallery{
background-color: black;
display: flex;
justify-content: space-between;
padding: 0px 20px;
}

.nav-mob-gallery img{
max-width: 70px;
margin: 20px;
}

.gallery-heading{
    text-align: center;
    font-weight: 300;
    margin: 20px 0px;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.475);
}

.gallery-container-mobile{
 display: flex;
 flex-direction: column;
 margin: 15px;
 gap: 15px;
}

.gallery-container-mobile img{
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.389);
}

.gallery-container-mobile h2{
    text-align: center;
    font-weight: 300;
    font-style: italic;
    font-size: 1.7rem;
  
}

.line-gallery{
    background:linear-gradient(90deg,rgb(0, 174, 255), rgb(0, 0, 0));
    height: 2px;
    width: 30%;
    margin: 20px auto;
}


.nav-gallery{
    display: flex;
    justify-content: space-between;
    background: linear-gradient(90deg,black, rgb(37, 37, 37), black);
    height: 100px;
    padding: 0 30px;
}

.gallery-links{
    margin-top: 37px;
    display: flex;
    gap: 60px;
}

.gallery-links a{
    text-decoration: none;
    color: white;
   
}

.logo-group{
    display: flex;
    gap: 20px;
    color: white;
}

.logo-group img{
    height: 60px;
    margin-top: 20px;
}

.logo-group h2{
    align-self: center;
    max-width: 300px;
    font-weight: 300;
}


.desktop-gallery-group {
display: flex;
flex-direction: column;
gap: 100px;
margin: 0 43vh;
}

.desktop-gallery-group h2{
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-weight: 300;
    font-size: 3rem;
}

.desktop-gallery-group h2 img{
    width: 100px;    
}
.gallery-img{
display: flex;
gap: 10px;
}
.gallery-img img{
width: 500px;

overflow: hidden;
}

.services-page{
    color: rgb(0, 140, 255);
    background-color: white;
}

.services-page .desktop-p{
max-width: 800px;
text-align: center;
margin: 0 auto;
}

.service-page-line{
    background-color: rgb(0, 136, 255);
}

.services-page h2{
    font-size: 2.5rem;
}

.services-page .desktop-p{
    color: black;
}

.work-group{
    margin-top: 50px;
    display: grid;
    gap: 20px;
}
.work h2{
    font-style: italic;
    text-align: center;
}
.work{
border-radius: 20px;
color: white;
background: linear-gradient( rgb(0, 115, 255),rgb(1, 187, 255));
margin: 0px 35px;
padding: 20px;
}
.work p{
    line-height: 2rem;
    margin:10px ;
}

.work-line{
    height: 3px;
    width: 20%;
    margin: 20px auto;
    background-color: white;
}

@media only screen and (min-width: 768px) {
    footer{
        display: flex;
        justify-content: space-between;
        padding: 50px;
        border-top: 1px solid rgb(0, 136, 255) ;
    }

    footer img{
        max-width: 120px;
        width: 100%;
    }
    footer .footer-left{
        text-align: center;
        font-size: 1.2rem;
    }
    footer p{
        font-size: 1.5rem;
        font-weight: 500;
        color: rgb(0, 136, 255);
    }

    .footer-right{
        font-size: 1.2rem;
        color: rgb(0, 140, 255);

     }

     .social-logo{
        justify-content: center;
        display: flex;
        gap: 20px;
        margin-top: 20px;
        font-size: 2.2rem;
     }
     .social-logo a{
        color: black;
     }
  }

  @media only screen and (max-width: 768px) {
  footer img{
   
    max-width: 100px;
    width: 100%;
  }

  footer{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-inline: 20px;
    margin-top: 50px 0px;
  }

   .footer-right{
    font-size: 1rem;
    margin-top: 30px;
    color: rgb(0, 140, 255);
   }

  .social-logo{
    justify-content: center;
    display: flex;
    gap: 20px;
    
 }
 .social-logo a{
    color: black;
    font-size:1.5rem;

 }

 .footer-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
 }

  }


#service-img{
    max-width: 500px;
    border-radius: 50%;
}

#service{
    display: flex;
    justify-content: space-around;
    margin: 50px 0px;
    align-items: center;
}

#service-text{
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 1.2rem;
    line-height: 2.5rem;
}

#service-text h3{
    font-variant: small-caps;
    font-size: 2.5rem;
    color: rgb(0, 136, 255);
}